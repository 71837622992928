import React from 'react'
import './Footer.css'

function Footer() {

    return (
        <footer className="footerWrapper">
            <div className="footerContainer">
                <div className="disclaimer">
                    You need to allow access to video and audio to place calls.<br/>
                    QVC does not store any data on its servers. It just opens a Peer to Peer Connection.
                </div>
                <div className="self">
                    Developed <span role='img' aria-label='heart-emoji'> </span> by <a href="https://www.adarshrs.com" target="_blank" rel="noopener noreferrer"> adarsh_r_s</a>
                </div>
            </div>
        </footer>
    )

}

export default Footer